export const GradientOverlays = ({ toColor, isHovered = false }: { toColor?: string, isHovered?: boolean }) => {
    if (!toColor) toColor = 'background';
    return (
        <>
            <div className={`${isHovered ? 'opacity-20' : 'opacity-100'} duration-100 absolute z-[10] inset-0 bg-[linear-gradient(30deg,var(--tw-gradient-stops))] from-transparent from-70% to-90% to-${toColor} `} />
            <div className={`${isHovered ? 'opacity-20' : 'opacity-100'} duration-100 absolute z-[10] inset-0 bg-gradient-to-tr from-transparent from-70% to-80% to-${toColor} `} />
            <div className={`${isHovered ? 'opacity-20' : 'opacity-50'} duration-100 absolute z-[10] inset-0 bg-gradient-to-b from-transparent from-50% to-70% to-${toColor} `} />
            <div className={`${isHovered ? 'opacity-20' : 'opacity-50'} duration-100 absolute z-[10] inset-0 bg-gradient-to-b from-transparent from-60% to-70% to-${toColor} `} />
            <div className={`${isHovered ? "opacity-20" : "opacity-50"} transition-all duration-100 absolute z-[40] inset-0 bg-gradient-to-tr from-transparent from-30% to-90% to-${toColor} `} />
        </>
    )
}

export const GradientOverlaysBlack = ({ isHovered = false }: { isHovered?: boolean }) => {
    const opacityClass = isHovered ? 'opacity-0' : 'opacity-60';
    return (
        <>
            <div className={`${opacityClass} transition-all duration-100 absolute z-[40] inset-0 bg-[linear-gradient(40deg,var(--tw-gradient-stops))] from-transparent from-60% to-80% to-black`} />
        </>
    )
}