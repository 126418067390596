type ZodiacSign = { sign: string, index: string; };
type DateRange = { start: [number, number], end: [number, number]; };


const zodiacSigns: (ZodiacSign & { range: DateRange })[] = [
  { sign: "Capricorn", index: "01", range: { start: [12, 21], end: [1, 20] } },
  { sign: "Aquarius", index: "02", range: { start: [1, 19], end: [2, 19] } },
  { sign: "Pisces", index: "03", range: { start: [2, 18], end: [3, 20] } },
  { sign: "Aries", index: "04", range: { start: [3, 19], end: [4, 20] } },
  { sign: "Taurus", index: "05", range: { start: [4, 19], end: [5, 21] } },
  { sign: "Gemini", index: "06", range: { start: [5, 20], end: [6, 21] } },
  { sign: "Cancer", index: "07", range: { start: [6, 20], end: [7, 23] } },
  { sign: "Leo", index: "08", range: { start: [7, 22], end: [8, 23] } },
  { sign: "Virgo", index: "09", range: { start: [8, 22], end: [9, 23] } },
  { sign: "Libra", index: "10", range: { start: [9, 22], end: [10, 23] } },
  { sign: "Scorpio", index: "11", range: { start: [10, 22], end: [11, 22] } },
  { sign: "Sagittarius", index: "12", range: { start: [11, 21], end: [12, 22] } }
];

function isDateInRange(month: number, day: number, range: DateRange): boolean {
  const dateValue = month * 100 + day;
  const startValue = range.start[0] * 100 + range.start[1];
  const endValue = range.end[0] * 100 + range.end[1];

  if (startValue <= endValue) {
    return dateValue >= startValue && dateValue <= endValue;
  } else {
    // Handle wrap around for Capricorn
    return dateValue >= startValue || dateValue <= endValue;
  }
}

export function getSunSignWithCusps(date: string): ZodiacSign | [ZodiacSign, ZodiacSign] {
  const [, month, day] = date.split('-').map(Number);

  const matchingSigns = zodiacSigns.filter(sign =>
    isDateInRange(month, day, sign.range)
  );

  if (matchingSigns.length === 1) {
    return matchingSigns[0];
  } else if (matchingSigns.length === 2) {
    return [matchingSigns[0], matchingSigns[1]];
  } else {
    throw new Error("Unable to determine zodiac sign");
  }
}