import { mapAddressToConstellation, mapAddressToGradient } from '@/lib/usernameUtils';
import { memo } from 'react';

export const UserBubble = ({ address, className, isUserBid }: { address: string, className?: string, isUserBid?: boolean }) => {
    const { angle, stop1, stop2 } = mapAddressToGradient(address, 0.8, 20, 50);
    const style = { backgroundImage: isUserBid ? `linear-gradient(${angle}, ${stop1}, ${stop2})` : 'none' };

    return (
        <div style={style}
            className={`${className}
              border-t border-muted-foreground/50 text-xs bg-primary-foreground/90 text-nowrap w-full text-center z-[101] rounded-b-lg text-primary/90 
            `}
        >
            <div className='w-full absolute top-0 left-0 z-[99] kit-date-regular'>
                {mapAddressToConstellation(address)}
            </div>
        </div>
    );
}

export const ResultBubble = ({ address, height, isUserBid }: { address: string, height?: number, isUserBid?: boolean }) => {
    const heightClass = height ? `h-${height}` : 'h-8';
    const { angle, stop1, stop2 } = mapAddressToGradient(address, 0.8, 20, 50);
    const style = { backgroundImage: isUserBid ? `linear-gradient(${angle}, ${stop1}, ${stop2})` : 'none' };

    return (
        <div style={style}
            className={`${heightClass} ${isUserBid ? ' text-white/90' : 'bg-black'}
              border-t border-muted-foreground/50  text-nowrap text-center z-[101] rounded-b-lg text-primary/90 
            `}
        >
            {mapAddressToConstellation(address)}
        </div>
    );
}

export const MemoizedUserBubble = memo(UserBubble);